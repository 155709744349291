import React from 'react';
import Admin_View_Posts from '../admin_view_posts/admin_view_posts.js';

// import post_listing from './post_listing.css';


function Post_Listing(props) {
    let lists = [
        {   'id': 1,
            'title': 'Content Title',
            'date': 'DECEMBER 16, 2020',
            'paragraph': "Every website faces two key questions: 1. What content do we have at hand? 2. What content should we produce? Answering those questions is the Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
        },
        {   'id': 2,
            'title': 'Dinosaurs never died and are Living Under Your House',
            'date': 'DECEMBER 16, 2020',
            'paragraph': `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut venenatis tellus in metus vulputate eu scelerisque felis imperdiet. Amet consectetur adipiscing elit ut aliquam purus sit amet luctus. Sit amet purus gravida quis blandit turpis cursus. Cursus metus aliquam eleifend mi in. Non sodales neque sodales ut etiam sit amet nisl. Iaculis urna id volutpat lacus laoreet non curabitur gravida. Nulla pellentesque dignissim enim sit amet venenatis urna. Etiam sit amet nisl purus in. Euismod quis viverra nibh cras pulvinar mattis nunc. Tincidunt id aliquet risus feugiat in.

            Diam in arcu cursus euismod quis viverra nibh. In hac habitasse platea dictumst vestibulum rhoncus est. Laoreet suspendisse interdum consectetur libero id faucibus. Aenean et tortor at risus viverra adipiscing. Faucibus purus in massa tempor. Pulvinar pellentesque habitant morbi tristique senectus et netus et. Amet aliquam id diam maecenas ultricies mi eget mauris. Nisl vel pretium lectus quam id leo in vitae turpis. Nisi est sit amet facilisis. Aliquam purus sit amet luctus venenatis lectus magna. Ultricies mi quis hendrerit dolor. Tellus at urna condimentum mattis pellentesque. Viverra mauris in aliquam sem fringilla. Quam nulla porttitor massa id neque aliquam vestibulum morbi blandit.
            
            Morbi tincidunt ornare massa eget egestas. Eget aliquet nibh praesent tristique magna sit amet. Purus viverra accumsan in nisl nisi scelerisque eu ultrices. Sollicitudin aliquam ultrices sagittis orci a scelerisque. Faucibus turpis in eu mi bibendum neque egestas congue quisque. Imperdiet massa tincidunt nunc pulvinar. Quis auctor elit sed vulputate mi sit amet mauris. Eu turpis egestas pretium aenean pharetra magna ac placerat vestibulum. Adipiscing elit duis tristique sollicitudin nibh. Lectus nulla at volutpat diam ut venenatis. Sit amet est placerat in egestas erat imperdiet sed. Amet nulla facilisi morbi tempus iaculis. Eu volutpat odio facilisis mauris sit amet massa. Tellus mauris a diam maecenas sed enim ut sem. Morbi quis commodo odio aenean sed. Vitae tortor condimentum lacinia quis vel eros donec. Montes nascetur ridiculus mus mauris vitae ultricies.
            
            Viverra nibh cras pulvinar mattis nunc sed. Sagittis orci a scelerisque purus. Nisi scelerisque eu ultrices vitae auctor eu augue ut lectus. Lobortis elementum nibh tellus molestie nunc non. In eu mi bibendum neque egestas congue quisque. Ut tellus elementum sagittis vitae et leo duis ut diam. Habitant morbi tristique senectus et. Mi eget mauris pharetra et ultrices neque ornare aenean euismod. In massa tempor nec feugiat. Commodo odio aenean sed adipiscing. Pulvinar neque laoreet suspendisse interdum. Dolor morbi non arcu risus quis varius quam quisque id. Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Nisl purus in mollis nunc sed. Varius duis at consectetur lorem donec massa sapien faucibus. Natoque penatibus et magnis dis. At elementum eu facilisis sed odio morbi quis commodo. In fermentum et sollicitudin ac orci phasellus egestas tellus rutrum. Ultrices mi tempus imperdiet nulla malesuada pellentesque elit eget. Erat pellentesque adipiscing commodo elit.
            
            Suspendisse sed nisi lacus sed viverra tellus in. Diam in arcu cursus euismod quis viverra nibh cras. Mattis aliquam faucibus purus in massa tempor. Imperdiet proin fermentum leo vel. Urna molestie at elementum eu facilisis sed odio morbi. Convallis convallis tellus id interdum velit laoreet. Volutpat diam ut venenatis tellus in metus vulputate eu. Vestibulum lectus mauris ultrices eros. Arcu risus quis varius quam. Proin sed libero enim sed. Habitant morbi tristique senectus et netus et. In est ante in nibh mauris cursus mattis molestie a. Quis auctor elit sed vulputate mi sit amet mauris. Vitae turpis massa sed elementum tempus egestas sed.
            
            Pretium lectus quam id leo in vitae turpis massa. Velit egestas dui id ornare arcu. Turpis massa tincidunt dui ut ornare lectus sit. Arcu non odio euismod lacinia. Turpis egestas sed tempus urna. Integer malesuada nunc vel risus commodo viverra maecenas accumsan. Odio aenean sed adipiscing diam. Faucibus purus in massa tempor. Pretium nibh ipsum consequat nisl vel pretium lectus. Ornare lectus sit amet est.
            
            Adipiscing elit duis tristique sollicitudin nibh sit amet commodo. Ante metus dictum at tempor. Amet commodo nulla facilisi nullam vehicula. Sed viverra tellus in hac. Vivamus arcu felis bibendum ut. Nunc sed augue lacus viverra. Imperdiet massa tincidunt nunc pulvinar sapien et ligula ullamcorper. Vivamus arcu felis bibendum ut tristique et egestas. Dictum non consectetur a erat nam at lectus urna duis. Ornare lectus sit amet est placerat in.
            
            Dignissim sodales ut eu sem. Est lorem ipsum dolor sit amet consectetur adipiscing elit pellentesque. Massa tempor nec feugiat nisl. Turpis egestas maecenas pharetra convallis. Euismod in pellentesque massa placerat duis ultricies lacus sed turpis. Metus vulputate eu scelerisque felis. Tellus rutrum tellus pellentesque eu tincidunt tortor. Nullam non nisi est sit amet facilisis. Venenatis lectus magna fringilla urna porttitor rhoncus dolor. Orci eu lobortis elementum nibh. Donec enim diam vulputate ut pharetra sit amet aliquam id. Ac odio tempor orci dapibus ultrices. Rutrum quisque non tellus orci ac auctor augue mauris augue. Lacus suspendisse faucibus interdum posuere lorem ipsum. A pellentesque sit amet porttitor eget dolor morbi non arcu. Sollicitudin aliquam ultrices sagittis orci a scelerisque purus semper eget. Tempus iaculis urna id volutpat lacus laoreet. Sed viverra tellus in hac habitasse platea.
            
            Diam quis enim lobortis scelerisque fermentum. Laoreet non curabitur gravida arcu ac tortor. Viverra aliquet eget sit amet tellus cras adipiscing enim. Integer eget aliquet nibh praesent tristique magna. Vitae tempus quam pellentesque nec nam aliquam sem et. Cursus in hac habitasse platea dictumst quisque sagittis. Non enim praesent elementum facilisis leo. Commodo nulla facilisi nullam vehicula. Dolor morbi non arcu risus quis. Lectus quam id leo in vitae turpis massa sed elementum. Scelerisque purus semper eget duis at tellus. Nibh tellus molestie nunc non blandit massa. Fames ac turpis egestas maecenas pharetra convallis posuere morbi.
            
            Ut ornare lectus sit amet. Libero volutpat sed cras ornare arcu dui vivamus arcu. Est sit amet facilisis magna etiam tempor orci eu lobortis. Id semper risus in hendrerit gravida. Aliquam eleifend mi in nulla posuere sollicitudin aliquam. Platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper. Ac placerat vestibulum lectus mauris ultrices eros in cursus. Sed pulvinar proin gravida hendrerit. Vitae aliquet nec ullamcorper sit. Ut tristique et egestas quis ipsum suspendisse. Nunc aliquet bibendum enim facilisis gravida neque convallis a cras. Varius morbi enim nunc faucibus a. Mollis nunc sed id semper risus in hendrerit. Ornare quam viverra orci sagittis eu. Nibh praesent tristique magna sit amet purus gravida quis. At tempor commodo ullamcorper a lacus. Lorem dolor sed viverra ipsum. Amet tellus cras adipiscing enim eu turpis egestas. Morbi non arcu risus quis varius.`,
        },
        {   'id': 3,
            'title': 'Content Title',
            'date': 'DECEMBER 16, 2020',
            'paragraph': `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Commodo sed egestas egestas fringilla phasellus. Faucibus in ornare quam viverra. Consequat ac felis donec et. Sit amet cursus sit amet dictum sit amet justo donec. Lacinia quis vel eros donec ac. Felis eget velit aliquet sagittis id consectetur purus ut. Eu nisl nunc mi ipsum faucibus vitae aliquet. Turpis tincidunt id aliquet risus feugiat in ante metus. Nulla facilisi morbi tempus iaculis urna id volutpat. At lectus urna duis convallis convallis tellus. Consectetur lorem donec massa sapien faucibus et. Amet commodo nulla facilisi nullam vehicula. Scelerisque fermentum dui faucibus in. Non curabitur gravida arcu ac tortor dignissim convallis. Sem integer vitae justo eget magna fermentum iaculis eu.

            Lacus sed turpis tincidunt id aliquet risus feugiat in ante. Suscipit tellus mauris a diam. Magnis dis parturient montes nascetur ridiculus. Id leo in vitae turpis massa. Odio pellentesque diam volutpat commodo sed egestas egestas fringilla. Semper risus in hendrerit gravida rutrum. Gravida quis blandit turpis cursus in hac habitasse platea. Adipiscing tristique risus nec feugiat in fermentum. Vitae et leo duis ut diam quam nulla. Dictum fusce ut placerat orci nulla.
            
            Odio tempor orci dapibus ultrices in iaculis nunc. Et molestie ac feugiat sed. Cursus turpis massa tincidunt dui ut ornare lectus sit amet. Vulputate odio ut enim blandit volutpat. Fermentum iaculis eu non diam phasellus. Ornare quam viverra orci sagittis eu volutpat odio facilisis. Ut consequat semper viverra nam libero. Netus et malesuada fames ac turpis egestas maecenas. Nunc faucibus a pellentesque sit amet porttitor eget dolor. Amet venenatis urna cursus eget nunc scelerisque viverra mauris. Id aliquet lectus proin nibh nisl condimentum. Justo donec enim diam vulputate ut pharetra sit. Vitae nunc sed velit dignissim sodales ut eu. Mauris pharetra et ultrices neque ornare aenean. Tortor condimentum lacinia quis vel eros. Mattis vulputate enim nulla aliquet porttitor lacus luctus. Venenatis cras sed felis eget velit aliquet sagittis id consectetur. Mus mauris vitae ultricies leo integer malesuada nunc vel. Et malesuada fames ac turpis egestas sed tempus.
            
            Faucibus nisl tincidunt eget nullam. Pellentesque sit amet porttitor eget dolor morbi non arcu risus. Vitae proin sagittis nisl rhoncus mattis rhoncus urna neque viverra. Diam maecenas ultricies mi eget mauris pharetra. Arcu vitae elementum curabitur vitae nunc sed. At lectus urna duis convallis convallis tellus id interdum. Morbi enim nunc faucibus a pellentesque sit amet porttitor. Tincidunt ornare massa eget egestas purus. Amet dictum sit amet justo donec enim diam. A pellentesque sit amet porttitor eget. Aliquet risus feugiat in ante metus dictum at tempor commodo. Nunc id cursus metus aliquam eleifend mi in nulla. Volutpat odio facilisis mauris sit amet massa vitae tortor condimentum. Non odio euismod lacinia at. Pulvinar mattis nunc sed blandit libero volutpat sed cras ornare. At tempor commodo ullamcorper a lacus.`
        },
        {   'id': 4,
            'title': 'HOW Many Chars can',
            'date': 'DECEMBER 16, 2020',
            'paragraph': `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Enim praesent elementum facilisis leo. Et malesuada fames ac turpis egestas. Faucibus nisl tincidunt eget nullam non nisi est sit. Parturient montes nascetur ridiculus mus mauris vitae ultricies. Mi sit amet mauris commodo quis imperdiet massa. Egestas dui id ornare arcu odio. In nibh mauris cursus mattis molestie a iaculis at erat. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur. Adipiscing elit duis tristique sollicitudin nibh sit amet commodo nulla. Dignissim convallis aenean et tortor at risus viverra. Id diam vel quam elementum pulvinar etiam. Id ornare arcu odio ut sem nulla pharetra diam sit.

            Ut tristique et egestas quis ipsum suspendisse. Amet tellus cras adipiscing enim eu. Ullamcorper morbi tincidunt ornare massa eget egestas purus. Velit sed ullamcorper morbi tincidunt. Vitae tortor condimentum lacinia quis vel eros donec ac. Ut faucibus pulvinar elementum integer enim neque volutpat ac tincidunt. Quam id leo in vitae turpis massa sed. Nisl condimentum id venenatis a. Iaculis eu non diam phasellus. Et netus et malesuada fames ac. Viverra maecenas accumsan lacus vel facilisis volutpat est.`
        },
        {   'id': 5,
            'title': 'Content Title',
            'date': 'DECEMBER 16, 2020',
            'paragraph': `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Amet nisl suscipit adipiscing bibendum est. Id porta nibh venenatis cras sed. Nam libero justo laoreet sit amet cursus sit amet. Vitae justo eget magna fermentum iaculis eu. Feugiat in ante metus dictum at tempor commodo ullamcorper a. Risus nullam eget felis eget nunc lobortis mattis. Et ultrices neque ornare aenean euismod elementum nisi quis eleifend. Aliquam sem fringilla ut morbi. Eget egestas purus viverra accumsan. Mauris augue neque gravida in. Consectetur adipiscing elit ut aliquam purus sit. Diam ut venenatis tellus in metus vulputate eu. Pellentesque nec nam aliquam sem et tortor consequat.

            Praesent semper feugiat nibh sed. Massa enim nec dui nunc mattis. Nibh sit amet commodo nulla facilisi nullam vehicula ipsum a. Ultrices in iaculis nunc sed augue lacus viverra vitae congue. Facilisis sed odio morbi quis commodo odio aenean. Morbi blandit cursus risus at ultrices mi. Feugiat in ante metus dictum at. Quis enim lobortis scelerisque fermentum dui faucibus. Id diam vel quam elementum. Sit amet nisl suscipit adipiscing bibendum est ultricies integer quis. Bibendum neque egestas congue quisque egestas. Donec pretium vulputate sapien nec sagittis aliquam malesuada bibendum. Volutpat diam ut venenatis tellus. Sapien eget mi proin sed libero enim sed. Pellentesque habitant morbi tristique senectus et netus. Posuere morbi leo urna molestie at. Interdum velit euismod in pellentesque. Lobortis elementum nibh tellus molestie nunc non blandit massa enim.
            
            Malesuada pellentesque elit eget gravida cum sociis. Semper risus in hendrerit gravida rutrum quisque non tellus. Scelerisque varius morbi enim nunc. Condimentum id venenatis a condimentum vitae sapien pellentesque habitant morbi. Enim nec dui nunc mattis enim ut. Congue nisi vitae suscipit tellus mauris a diam maecenas. Facilisis volutpat est velit egestas dui id ornare arcu odio. Id volutpat lacus laoreet non curabitur. Amet nisl suscipit adipiscing bibendum est ultricies. Porta non pulvinar neque laoreet. A arcu cursus vitae congue mauris rhoncus aenean vel elit. Donec enim diam vulputate ut pharetra. Nulla pellentesque dignissim enim sit amet venenatis urna cursus eget. Est sit amet facilisis magna etiam tempor. Ut placerat orci nulla pellentesque dignissim enim. Proin sed libero enim sed faucibus. Sit amet consectetur adipiscing elit ut aliquam.
            
            Gravida cum sociis natoque penatibus et. Aliquet nec ullamcorper sit amet risus nullam eget felis. Vitae elementum curabitur vitae nunc sed. Arcu non odio euismod lacinia at quis risus sed vulputate. Vitae congue eu consequat ac felis donec. Tincidunt praesent semper feugiat nibh sed pulvinar proin gravida. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Turpis egestas pretium aenean pharetra magna ac. Quam vulputate dignissim suspendisse in. Commodo nulla facilisi nullam vehicula ipsum a. Aliquam malesuada bibendum arcu vitae elementum curabitur vitae nunc. Massa tincidunt dui ut ornare lectus. Gravida cum sociis natoque penatibus et magnis dis parturient montes. Tempor orci dapibus ultrices in iaculis nunc sed augue lacus. Nisl nisi scelerisque eu ultrices vitae auctor. Proin libero nunc consequat interdum varius sit. Aliquam purus sit amet luctus venenatis lectus magna.
            
            Ornare quam viverra orci sagittis eu volutpat odio facilisis. Metus dictum at tempor commodo ullamcorper. Convallis convallis tellus id interdum velit laoreet. Netus et malesuada fames ac turpis. In hac habitasse platea dictumst quisque sagittis purus sit. Gravida dictum fusce ut placerat orci nulla pellentesque dignissim. In arcu cursus euismod quis viverra nibh cras pulvinar. Mauris commodo quis imperdiet massa tincidunt nunc pulvinar sapien. Ut venenatis tellus in metus vulputate eu scelerisque felis. Consequat semper viverra nam libero justo laoreet sit amet cursus. At consectetur lorem donec massa sapien faucibus. Et malesuada fames ac turpis. Sit amet est placerat in egestas erat imperdiet. Rutrum tellus pellentesque eu tincidunt. Blandit turpis cursus in hac. Vulputate enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Massa tincidunt dui ut ornare lectus sit amet est placerat. Nulla aliquet porttitor lacus luctus.
            
            Aenean euismod elementum nisi quis eleifend. Arcu bibendum at varius vel. Diam quis enim lobortis scelerisque fermentum dui faucibus. In hac habitasse platea dictumst. Fames ac turpis egestas maecenas pharetra convallis posuere morbi leo. Ornare aenean euismod elementum nisi quis eleifend quam. Ut ornare lectus sit amet est. Leo a diam sollicitudin tempor id. Magna etiam tempor orci eu lobortis elementum nibh tellus molestie. In metus vulputate eu scelerisque. Adipiscing at in tellus integer. Neque sodales ut etiam sit amet nisl purus in mollis. Elit sed vulputate mi sit amet. Nec sagittis aliquam malesuada bibendum arcu vitae elementum. Consectetur lorem donec massa sapien faucibus et molestie ac feugiat. Nec feugiat nisl pretium fusce id velit. Et netus et malesuada fames ac turpis egestas integer eget. Ut lectus arcu bibendum at varius vel pharetra. Vel orci porta non pulvinar neque laoreet suspendisse.`
        },
        {   'id': 6,
            'title': 'Content Title',
            'date': 'DECEMBER 16, 2020',
            'paragraph': "Every website faces two key questions: 1. What content do we have at hand? 2. What content should we produce? Answering those questions is the Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
        },
        {   'id': 7,
            'title': 'New disease HYPOCONDRIAC-TIC-Sliide enters hospital',
            'date': 'DECEMBER 16, 2020',
            'paragraph': `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Amet nisl suscipit adipiscing bibendum est. Id porta nibh venenatis cras sed. Nam libero justo laoreet sit amet cursus sit amet. Vitae justo eget magna fermentum iaculis eu. Feugiat in ante metus dictum at tempor commodo ullamcorper a. Risus nullam eget felis eget nunc lobortis mattis. Et ultrices neque ornare aenean euismod elementum nisi quis eleifend. Aliquam sem fringilla ut morbi. Eget egestas purus viverra accumsan. Mauris augue neque gravida in. Consectetur adipiscing elit ut aliquam purus sit. Diam ut venenatis tellus in metus vulputate eu. Pellentesque nec nam aliquam sem et tortor consequat.

            Praesent semper feugiat nibh sed. Massa enim nec dui nunc mattis. Nibh sit amet commodo nulla facilisi nullam vehicula ipsum a. Ultrices in iaculis nunc sed augue lacus viverra vitae congue. Facilisis sed odio morbi quis commodo odio aenean. Morbi blandit cursus risus at ultrices mi. Feugiat in ante metus dictum at. Quis enim lobortis scelerisque fermentum dui faucibus. Id diam vel quam elementum. Sit amet nisl suscipit adipiscing bibendum est ultricies integer quis. Bibendum neque egestas congue quisque egestas. Donec pretium vulputate sapien nec sagittis aliquam malesuada bibendum. Volutpat diam ut venenatis tellus. Sapien eget mi proin sed libero enim sed. Pellentesque habitant morbi tristique senectus et netus. Posuere morbi leo urna molestie at. Interdum velit euismod in pellentesque. Lobortis elementum nibh tellus molestie nunc non blandit massa enim.
            
            Malesuada pellentesque elit eget gravida cum sociis. Semper risus in hendrerit gravida rutrum quisque non tellus. Scelerisque varius morbi enim nunc. Condimentum id venenatis a condimentum vitae sapien pellentesque habitant morbi. Enim nec dui nunc mattis enim ut. Congue nisi vitae suscipit tellus mauris a diam maecenas. Facilisis volutpat est velit egestas dui id ornare arcu odio. Id volutpat lacus laoreet non curabitur. Amet nisl suscipit adipiscing bibendum est ultricies. Porta non pulvinar neque laoreet. A arcu cursus vitae congue mauris rhoncus aenean vel elit. Donec enim diam vulputate ut pharetra. Nulla pellentesque dignissim enim sit amet venenatis urna cursus eget. Est sit amet facilisis magna etiam tempor. Ut placerat orci nulla pellentesque dignissim enim. Proin sed libero enim sed faucibus. Sit amet consectetur adipiscing elit ut aliquam.
            
            Gravida cum sociis natoque penatibus et. Aliquet nec ullamcorper sit amet risus nullam eget felis. Vitae elementum curabitur vitae nunc sed. Arcu non odio euismod lacinia at quis risus sed vulputate. Vitae congue eu consequat ac felis donec. Tincidunt praesent semper feugiat nibh sed pulvinar proin gravida. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Turpis egestas pretium aenean pharetra magna ac. Quam vulputate dignissim suspendisse in. Commodo nulla facilisi nullam vehicula ipsum a. Aliquam malesuada bibendum arcu vitae elementum curabitur vitae nunc. Massa tincidunt dui ut ornare lectus. Gravida cum sociis natoque penatibus et magnis dis parturient montes. Tempor orci dapibus ultrices in iaculis nunc sed augue lacus. Nisl nisi scelerisque eu ultrices vitae auctor. Proin libero nunc consequat interdum varius sit. Aliquam purus sit amet luctus venenatis lectus magna.
            
            Ornare quam viverra orci sagittis eu volutpat odio facilisis. Metus dictum at tempor commodo ullamcorper. Convallis convallis tellus id interdum velit laoreet. Netus et malesuada fames ac turpis. In hac habitasse platea dictumst quisque sagittis purus sit. Gravida dictum fusce ut placerat orci nulla pellentesque dignissim. In arcu cursus euismod quis viverra nibh cras pulvinar. Mauris commodo quis imperdiet massa tincidunt nunc pulvinar sapien. Ut venenatis tellus in metus vulputate eu scelerisque felis. Consequat semper viverra nam libero justo laoreet sit amet cursus. At consectetur lorem donec massa sapien faucibus. Et malesuada fames ac turpis. Sit amet est placerat in egestas erat imperdiet. Rutrum tellus pellentesque eu tincidunt. Blandit turpis cursus in hac. Vulputate enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Massa tincidunt dui ut ornare lectus sit amet est placerat. Nulla aliquet porttitor lacus luctus.
            
            Aenean euismod elementum nisi quis eleifend. Arcu bibendum at varius vel. Diam quis enim lobortis scelerisque fermentum dui faucibus. In hac habitasse platea dictumst. Fames ac turpis egestas maecenas pharetra convallis posuere morbi leo. Ornare aenean euismod elementum nisi quis eleifend quam. Ut ornare lectus sit amet est. Leo a diam sollicitudin tempor id. Magna etiam tempor orci eu lobortis elementum nibh tellus molestie. In metus vulputate eu scelerisque. Adipiscing at in tellus integer. Neque sodales ut etiam sit amet nisl purus in mollis. Elit sed vulputate mi sit amet. Nec sagittis aliquam malesuada bibendum arcu vitae elementum. Consectetur lorem donec massa sapien faucibus et molestie ac feugiat. Nec feugiat nisl pretium fusce id velit. Et netus et malesuada fames ac turpis egestas integer eget. Ut lectus arcu bibendum at varius vel pharetra. Vel orci porta non pulvinar neque laoreet suspendisse.`
        },
        {   'id': 8,
            'title': 'Content Title',
            'date': 'DECEMBER 16, 2020',
            'paragraph': "Every website faces two key questions: 1. What content do we have at hand? 2. What content should we produce? Answering those questions is the Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
        },
        {   'id': 9,
            'title': 'Content Title',
            'date': 'DECEMBER 16, 2020',
            'paragraph': `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Justo donec enim diam vulputate ut pharetra sit amet aliquam. Purus ut faucibus pulvinar elementum integer. Suspendisse potenti nullam ac tortor vitae purus faucibus ornare suspendisse. Adipiscing diam donec adipiscing tristique risus nec feugiat in. Sit amet volutpat consequat mauris. Metus dictum at tempor commodo. Ut enim blandit volutpat maecenas volutpat blandit aliquam etiam erat. Bibendum ut tristique et egestas quis ipsum suspendisse ultrices gravida. Ut eu sem integer vitae. Massa vitae tortor condimentum lacinia quis vel. Mi ipsum faucibus vitae aliquet nec. Posuere lorem ipsum dolor sit amet consectetur adipiscing elit duis. Vitae et leo duis ut diam quam. Varius morbi enim nunc faucibus a pellentesque sit amet.

            Vestibulum lectus mauris ultrices eros. Aliquam sem et tortor consequat id porta. Pretium quam vulputate dignissim suspendisse in. Maecenas volutpat blandit aliquam etiam erat velit scelerisque in. Sit amet venenatis urna cursus eget nunc scelerisque viverra. In hac habitasse platea dictumst vestibulum rhoncus est pellentesque elit. Cras ornare arcu dui vivamus arcu felis bibendum. Ut ornare lectus sit amet. Pellentesque id nibh tortor id. Pulvinar pellentesque habitant morbi tristique senectus et netus et. Velit ut tortor pretium viverra suspendisse potenti nullam ac tortor.
            
            Placerat orci nulla pellentesque dignissim enim sit. Nunc non blandit massa enim nec dui nunc mattis enim. Ante in nibh mauris cursus mattis molestie. Aliquam purus sit amet luctus venenatis lectus. Urna molestie at elementum eu facilisis sed odio morbi quis. Pellentesque adipiscing commodo elit at imperdiet dui accumsan. Quis hendrerit dolor magna eget est lorem ipsum dolor sit. Etiam tempor orci eu lobortis elementum nibh tellus molestie. Non diam phasellus vestibulum lorem sed risus ultricies. Lacus sed turpis tincidunt id aliquet risus feugiat in ante. Tortor posuere ac ut consequat semper. Bibendum at varius vel pharetra. Posuere morbi leo urna molestie at elementum. Vestibulum morbi blandit cursus risus. Pretium fusce id velit ut tortor pretium viverra suspendisse. Nunc faucibus a pellentesque sit amet. Lacus luctus accumsan tortor posuere ac ut consequat semper. Odio ut sem nulla pharetra diam sit amet.
            
            Tincidunt ornare massa eget egestas purus viverra accumsan. Eget sit amet tellus cras adipiscing enim eu. Pulvinar pellentesque habitant morbi tristique senectus et netus et malesuada. Condimentum mattis pellentesque id nibh tortor id aliquet lectus proin. At volutpat diam ut venenatis. Faucibus a pellentesque sit amet porttitor eget dolor morbi. Scelerisque varius morbi enim nunc. Consectetur adipiscing elit ut aliquam purus sit amet luctus. Mi eget mauris pharetra et ultrices neque. Sed risus ultricies tristique nulla. Auctor augue mauris augue neque gravida in. Dui sapien eget mi proin sed libero. Dignissim diam quis enim lobortis scelerisque fermentum dui faucibus in. Risus feugiat in ante metus. Id aliquet risus feugiat in ante metus. Quis ipsum suspendisse ultrices gravida dictum.
            
            Vulputate dignissim suspendisse in est ante in nibh mauris. Faucibus in ornare quam viverra orci sagittis eu volutpat. Risus at ultrices mi tempus imperdiet nulla malesuada pellentesque. Condimentum vitae sapien pellentesque habitant. Est sit amet facilisis magna etiam. Condimentum id venenatis a condimentum vitae sapien pellentesque habitant. Ultricies lacus sed turpis tincidunt id. Nisl suscipit adipiscing bibendum est ultricies integer quis auctor. Congue quisque egestas diam in arcu cursus euismod quis viverra. Tincidunt vitae semper quis lectus. A diam sollicitudin tempor id eu nisl nunc.
            
            Posuere ac ut consequat semper viverra nam. Eget sit amet tellus cras adipiscing enim eu. Vel orci porta non pulvinar neque laoreet suspendisse interdum consectetur. Faucibus turpis in eu mi bibendum neque egestas congue. Dolor purus non enim praesent elementum facilisis leo vel fringilla. Nisl rhoncus mattis rhoncus urna neque viverra justo. Sit amet consectetur adipiscing elit duis. Id velit ut tortor pretium. Ultricies leo integer malesuada nunc vel risus commodo viverra. Vestibulum mattis ullamcorper velit sed ullamcorper. Vulputate eu scelerisque felis imperdiet proin fermentum leo vel. Aliquam etiam erat velit scelerisque in dictum non consectetur a. Cras ornare arcu dui vivamus arcu felis bibendum ut tristique. Odio euismod lacinia at quis risus sed vulputate odio ut. Blandit aliquam etiam erat velit scelerisque. Elementum integer enim neque volutpat ac tincidunt vitae semper. Adipiscing vitae proin sagittis nisl rhoncus mattis. Et netus et malesuada fames. Interdum posuere lorem ipsum dolor.
            
            Magna sit amet purus gravida quis blandit turpis cursus. Arcu risus quis varius quam quisque. Sagittis orci a scelerisque purus semper eget duis at tellus. Rhoncus aenean vel elit scelerisque mauris pellentesque. Ut sem nulla pharetra diam sit amet nisl suscipit. Non diam phasellus vestibulum lorem sed risus ultricies tristique nulla. Quam id leo in vitae turpis massa sed elementum tempus. Imperdiet proin fermentum leo vel orci porta non pulvinar. Consequat semper viverra nam libero. Nunc sed blandit libero volutpat sed cras ornare arcu dui. Bibendum neque egestas congue quisque egestas diam in. Odio aenean sed adipiscing diam donec adipiscing. Dictumst quisque sagittis purus sit amet volutpat consequat mauris. Non nisi est sit amet facilisis magna etiam tempor orci. Turpis in eu mi bibendum neque egestas. Massa vitae tortor condimentum lacinia quis vel eros. Donec enim diam vulputate ut pharetra sit.
            
            Pharetra diam sit amet nisl suscipit. At lectus urna duis convallis convallis tellus. In dictum non consectetur a erat nam. Tincidunt id aliquet risus feugiat. Ante in nibh mauris cursus mattis molestie a iaculis. Leo duis ut diam quam. Eget gravida cum sociis natoque penatibus et. Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Lectus mauris ultrices eros in. Sit amet consectetur adipiscing elit duis tristique sollicitudin nibh sit. Nunc non blandit massa enim nec dui. Id cursus metus aliquam eleifend mi in nulla posuere. Bibendum at varius vel pharetra vel turpis nunc. Mauris sit amet massa vitae tortor condimentum lacinia quis vel. Lorem ipsum dolor sit amet consectetur adipiscing elit. Varius duis at consectetur lorem donec massa. Cursus turpis massa tincidunt dui ut ornare lectus sit amet.`
        },
        {   'id': 10,
            'title': 'Content Title have to test how long a title can be before it commits UI/UX atrocisites',
            'date': 'DECEMBER 16, 2020',
            'paragraph': "Every website faces two key questions: 1. What content do we have at hand? 2. What content should we produce? Answering those questions is the Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
        },
        
    ];


    return (
        <div className='page post_page'>
            <button>add post button</button>
            {lists.map((article) => 
                <Admin_View_Posts id={article.id} title={article.title.length < 25 ? article.title.trimEnd() : (article.title.substring(0, 25).trimEnd() + '...')} date={article.date} paragraph={(article.paragraph.substring(0, 150) + "...")} read={Math.ceil((article.paragraph.split(' ').length)/250)} />
            )}
        </div>

    );
}

export default Post_Listing;